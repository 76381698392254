.u-unstyled-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.u-unstyled-btn {
  padding: 0;
  outline: none;
  border: none;
  box-shadow: none;
  background: transparent;
}

.u-nomargin {
  margin: 0;
}

// Number field without standard appearance(arrows on the right side)
input[type="number"].u-no-arrows::-webkit-outer-spin-button,
input[type="number"].u-no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"].u-no-arrows {
  -moz-appearance: textfield;
}

.margin-bot-10 {
  margin-bottom: 10px;
}
