
.field__item {
  margin-bottom: 15px;
}

.field__label {
  display: inline;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  font-family: $header-font;
  color: $charcoal-grey;

  &_bigger {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
  }
  &_medium {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
}

.field__help-block {
  margin: 2px 0 0;
  font-family: $text-font;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  color: $charcoal-grey;
}

.checkbox__field,
.radio__field {
  position: relative;
  min-height: 24px;
}

.radio__field input[type=radio] {
  position: absolute;
  visibility: hidden;
  &:checked ~ .radio__check {
    border: 2px solid $mainTenantColor;
    background-color: $mainTenantColor;
  }

  &:checked ~ .radio__label {
    color: $charcoal-grey;
  }

  &[disabled]:not(:checked) ~ .radio__check {
    background-color: $white-smoke;
  }
}

.checkbox__field input[type=checkbox] {
  position: absolute;
  visibility: hidden;
  &:checked ~ .checkbox__check {
    border: 2px solid $mainTenantColor;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 4px;
      width: 7px;
      height: 11px;
      border-width: 0 2px 2px 0;
      border-style: solid;
      border-color: $mainTenantColor;
      transform: rotate(45deg);
    }
  }

  &[disabled] ~ .checkbox__check {
    background-color: $white-smoke;
  }

  &:checked ~ .checkbox__label {
    color: $charcoal-grey;
  }
}

.checkbox__label,
.radio__label {
  position: relative;
  z-index: 3;
  display: block;
  margin: 0;
  padding: 0 0 0 27px;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: $text-font;
  color: $charcoal-grey;

  &_smaller {
    font-size: 14px;
    line-height: 18px;
  }
}

.checkbox__check,
.radio__check {
  display: block;
  position: absolute;
  border: 2px solid $pinkish-grey;
  background: #fff;
  height: 18px;
  width: 18px;
  top: 3px;
  left: 0;
  z-index: 2;
  transition: border .25s linear;

  &[disabled] {
    background-color: $white-smoke;
  }

  &_smaller {
    top: 1px;
  }
}

.radio__check {
  border-radius: 100%;
}

.yesno__field {
  display: flex;

  .radio__field {
    margin-top: 0;
    margin-right: 20px;
  }
}
