
/* Color palette */

$header-font: 'Montserrat', sans-serif;
$text-font: 'Roboto', sans-serif;

$charcoal-grey: #333;
$warm-grey: #777;

$border-color: #e7e7e7;
$typical-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.05);
$section-background: #f9f9f9;

$mainTenantColor: #2a2a32;
$mainTenantColorHover: #727272;


$white-smoke: #f6f6f6;
$pinkish-grey: #ccc;
