.bc-btn {
  max-width: 100%;
  height: 47px;
  border-radius: 25px;
  font-family: $header-font;
  font-size: 14px;
  line-height: 1.3;
}

.screen_btn {
  width: 270px;
}
