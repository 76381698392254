/* You can add global styles to this file, and also import other style files */
@import 'assets/sass/00-utilities/variables';

body, html {
  height: 100%;
  width: 100%;
  color: $charcoal-grey;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import 'assets/sass/00-utilities/mediaquery';
@import 'assets/sass/00-utilities/mixins';
@import 'assets/sass/00-utilities/helpers';

@import 'assets/sass/01-atoms/typography';
@import 'assets/sass/01-atoms/form';
@import 'assets/sass/01-atoms/btn';


/* The below are not to be changed, as they are success ICON colors always green */
.tenant-theme .success {
  color: #50e3c2;
}
.tenant-theme .finished {
  color: #50e3c2;
}
.tenant-theme .success-icon {
  color: #50e3c2;
}
